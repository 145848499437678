// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-templates-categories-category-loadmore-js": () => import("./../../../src/templates/categories/category-loadmore.js" /* webpackChunkName: "component---src-templates-categories-category-loadmore-js" */),
  "component---src-templates-categories-category-paginated-js": () => import("./../../../src/templates/categories/category-paginated.js" /* webpackChunkName: "component---src-templates-categories-category-paginated-js" */),
  "component---src-templates-categories-category-paginated-vids-revs-js": () => import("./../../../src/templates/categories/category-paginated-vids-revs.js" /* webpackChunkName: "component---src-templates-categories-category-paginated-vids-revs-js" */),
  "component---src-templates-categories-category-promotion-js": () => import("./../../../src/templates/categories/category-promotion.js" /* webpackChunkName: "component---src-templates-categories-category-promotion-js" */),
  "component---src-templates-categories-category-regular-js": () => import("./../../../src/templates/categories/category-regular.js" /* webpackChunkName: "component---src-templates-categories-category-regular-js" */),
  "component---src-templates-games-game-js": () => import("./../../../src/templates/games/game.js" /* webpackChunkName: "component---src-templates-games-game-js" */),
  "component---src-templates-page-about-us-js": () => import("./../../../src/templates/page/about-us.js" /* webpackChunkName: "component---src-templates-page-about-us-js" */),
  "component---src-templates-page-activation-user-js": () => import("./../../../src/templates/page/activation-user.js" /* webpackChunkName: "component---src-templates-page-activation-user-js" */),
  "component---src-templates-page-banking-page-js": () => import("./../../../src/templates/page/banking-page.js" /* webpackChunkName: "component---src-templates-page-banking-page-js" */),
  "component---src-templates-page-game-page-js": () => import("./../../../src/templates/page/game-page.js" /* webpackChunkName: "component---src-templates-page-game-page-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-page-live-casino-js": () => import("./../../../src/templates/page/live-casino.js" /* webpackChunkName: "component---src-templates-page-live-casino-js" */),
  "component---src-templates-page-lottery-js": () => import("./../../../src/templates/page/lottery.js" /* webpackChunkName: "component---src-templates-page-lottery-js" */),
  "component---src-templates-page-page-score-js": () => import("./../../../src/templates/page/page-score.js" /* webpackChunkName: "component---src-templates-page-page-score-js" */),
  "component---src-templates-page-prediction-game-js": () => import("./../../../src/templates/page/prediction-game.js" /* webpackChunkName: "component---src-templates-page-prediction-game-js" */),
  "component---src-templates-page-profile-js": () => import("./../../../src/templates/page/profile.js" /* webpackChunkName: "component---src-templates-page-profile-js" */),
  "component---src-templates-page-racing-js": () => import("./../../../src/templates/page/racing.js" /* webpackChunkName: "component---src-templates-page-racing-js" */),
  "component---src-templates-page-register-js": () => import("./../../../src/templates/page/register.js" /* webpackChunkName: "component---src-templates-page-register-js" */),
  "component---src-templates-page-reset-password-js": () => import("./../../../src/templates/page/reset-password.js" /* webpackChunkName: "component---src-templates-page-reset-password-js" */),
  "component---src-templates-page-slot-page-js": () => import("./../../../src/templates/page/slot-page.js" /* webpackChunkName: "component---src-templates-page-slot-page-js" */),
  "component---src-templates-page-virtual-sports-js": () => import("./../../../src/templates/page/virtual-sports.js" /* webpackChunkName: "component---src-templates-page-virtual-sports-js" */),
  "component---src-templates-page-withdraw-js": () => import("./../../../src/templates/page/withdraw.js" /* webpackChunkName: "component---src-templates-page-withdraw-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-redirect-301-js": () => import("./../../../src/templates/redirect-301.js" /* webpackChunkName: "component---src-templates-redirect-301-js" */)
}

